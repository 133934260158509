import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DocTable from '../DocTable';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  heroContent: {
    maxWidth: 900,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 1}px`,
  },
  paper: {
    padding: theme.spacing.unit * 1.5,
    margin: theme.spacing.unit * 0.8,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
});

function HeaderInfo(props) {
  const { classes } = props;

  return (
    <div>
      <div className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Documentation
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          component="p"
        >
          Cycle Day can be fully customized to your needs.
        </Typography>

        <Grid container>
          <Grid item sm={12} md={12} lg={6}>
            <Paper className={classes.paper}>
              <Typography variant="h5" color="textPrimary">
                Setup Google Sheet
              </Typography>
              <img
                width="100%"
                src="/static/media/SheetTemplate.png"
                alt="SheetTemplate"
              />
            </Paper>
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            <Paper className={classes.paper}>
              <Button
                target="_blank"
                href="https://docs.google.com/spreadsheets/d/1eMOqq8RUqYDQgNDYC180vzRECzVQa6oPHA_4xpKBEkE/copy"
                variant="contained"
                color="primary"
              >
                Copy Google sheet Template
              </Button>
              <br />
              <br />
              <Typography color="textPrimary" component="p">
                Cycle Day gets data from a Google Sheets spreadsheet using the{' '}
                <a
                  target="_blank"
                  href="https://developers.google.com/sheets/api/"
                  rel="noopener noreferrer"
                >
                  Sheets API
                </a>
                . The Google Sheet should be configure to
                <b> Anyone with the link: can view</b>. A template for the
                Google Sheet can be copied to your Google account with the link
                above.
              </Typography>
              <br />
              <Typography color="textPrimary" component="p">
                The cells outlined in <font color={'red'}>red</font> are
                <b> required</b> to be filled with a value. Data is pulled from
                your website using IMPORTXML(url, xpath_query). If cells A1, B1
                and C1 are not filled this will cause an error.
              </Typography>
              <br />
              <Typography color="textPrimary" component="p">
                The cycle day is pulled from cell <b>C1</b> by the application.
              </Typography>
              <br />
              <Typography color="textPrimary" component="p">
                You will need to acquire an API key from the Google Developer
                Console.{' '}
                <a
                  target="_blank"
                  href="https://developers.google.com/sheets/api/guides/authorizing#APIKey"
                  rel="noopener noreferrer"
                >
                  Get API key HERE
                </a>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <br />
        <br />
        <Typography variant="h5" color="textPrimary">
          Plist Setup
        </Typography>
        <Typography color="textSecondary" component="p">
          * Preference plist is location in: {'{App Directory}'}/Cycle Day.app
          /Contents/Resources/Preferences.plist
        </Typography>
        <Typography color="textSecondary" component="p">
          * To restore default settings please reinstall the app.
        </Typography>
      </div>
      <DocTable />
    </div>
  );
}

HeaderInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderInfo);
