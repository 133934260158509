import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
// icons
import TouchApp from '@material-ui/icons/TouchApp';
import Build from '@material-ui/icons/Build';
import Storage from '@material-ui/icons/Storage';

const styles = theme => ({
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing.unit * 2,
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  media: {
    padding: 0,
    height: 150,
    width: 150,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const projects = [
  {
    title: 'Easy to Use',
    icon: 'TouchApp',
    description: ['Displays the current cycle day in the menu bar.'],
  },
  {
    title: 'Easy to Configure',
    icon: 'Build',
    description: ['Uses a simple plist to configure the application.'],
  },
  {
    title: 'Integration',
    icon: 'Storage',
    description: [
      'Utilizes Google Sheets API to import the current cycle day.',
    ],
  },
];

function SimpleCard(props) {
  const { classes } = props;

  return (
    <div>
      <Grid container spacing={40} alignItems="flex-end">
        {projects.map(tier => (
          // Enterprise card is full width at sm breakpoint
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={4}
          >
            <Card>
              <CardHeader
                title={tier.title}
                subheader={tier.subheader}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                action={
                  tier.icon === 'TouchApp' ? (
                    <TouchApp />
                  ) : tier.icon === 'Build' ? (
                    <Build />
                  ) : tier.icon === 'Storage' ? (
                    <Storage />
                  ) : null
                }
                className={classes.cardHeader}
              />
              <CardContent>
                {tier.description.map(line => (
                  <Typography variant="subtitle1" align="center" key={line}>
                    {line}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleCard);
