import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

let id = 0;
function createData(date, version, information) {
  id += 1;
  return { id, date, version, information };
}

const rows = [
  createData(
    '03/06/2019',
    '1.2.0',
    'External links in UI now open the default browser, plist is now located in the app resource folder.',
  ),
  createData(
    '01/23/2019',
    '1.1.0',
    'Added customizable UI in popover via html.',
  ),
  createData('12/17/2018', '1.0.2', 'Created package installer.'),
  createData('05/12/2018', '1.0.1', 'Bug fixes.'),
  createData('03/20/2018', '1.0.0', 'Initial release.'),
];

function SimpleTable(props) {
  const { classes } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Version</TableCell>
            <TableCell>Information</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.version}</TableCell>
              <TableCell>{row.information}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTable);
