import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

let id = 0;
function createData(preference, description, datatype, defaultValue) {
  id += 1;
  return { id, preference, description, datatype, defaultValue };
}

const rows = [
  createData(
    'spreadsheetId',
    'ID of the spreedsheet used for data import. https://docs.google.com/spreadsheets/d/spreadsheetId/edit',
    'String',
    '',
  ),
  createData(
    'spreadsheetName',
    'Name of the spreedsheet used for data import.',
    'String',
    '',
  ),
  createData(
    'sheetsAPIKey',
    '	API Key acquired from Google Sheets API',
    'String',
    '',
  ),
  createData(
    'updateInterval',
    '	How often app with look for and update (seconds)',
    'Number',
    '7200',
  ),
  createData(
    'timerInterval',
    'How often app will check if it needs to run update interval (seconds)',
    'Number',
    '2',
  ),
  createData(
    'spamInterval',
    'Delay for spam prevention (seconds)',
    'Number',
    '100',
  ),
  createData(
    'showErrors',
    'Show errors made my application not being configured correctly.	',
    'Boolean',
    'YES',
  ),
  createData(
    'showMenuBar',
    '	Show in menu bar. Included for Sheets API quota testing.',
    'Boolean',
    'YES',
  ),
  createData(
    'spamProtection',
    'Delays the click to update cycle day. Prevents maxing out API quota.	',
    'Boolean',
    'YES',
  ),
];

function SimpleTable(props) {
  const { classes } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Preference</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="right">Data Type</TableCell>
            <TableCell align="right">Default Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.preference}</TableCell>
              <TableCell>
                {row.description.includes('d/spreadsheetId/edit') ? (
                  <span>
                    https://docs.google.com/spreadsheets/d/
                    <font color={'red'}>spreadsheetId</font>/edit
                  </span>
                ) : row.description.includes(
                    'Name of the spreedsheet used for data import',
                  ) ? (
                  <span>
                    {row.description}
                    <img
                      height="48"
                      src="/static/media/sheetNameHelp.png"
                      alt="sheetNameHelp"
                    />
                  </span>
                ) : (
                  <span>{row.description}</span>
                )}
              </TableCell>
              <TableCell>{row.datatype}</TableCell>
              <TableCell>{row.defaultValue}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTable);
