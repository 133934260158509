import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
});

function HeaderInfo(props) {
  const { classes } = props;

  return (
    <div className={classes.heroContent}>
      <Typography
        component="h1"
        variant="h2"
        align="center"
        color="textPrimary"
        gutterBottom
      >
        Cycle Day
      </Typography>
      <Typography
        variant="h6"
        align="center"
        color="textSecondary"
        component="p"
      >
        Never ask what day it is again.
      </Typography>
      <Paper className={classes.root} elevation={1}>
        <img width="100%" src="/static/media/preview.png" alt="preview" />
      </Paper>
    </div>
  );
}

HeaderInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderInfo);
